import React, { useState } from 'react'
import styled from 'styled-components'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import {
  mobileVW,
  desktopVW,
  font,
  colors,
  letterSpacing,
  desktopBreakpoint,
  mobileBreakpoint,
} from '../styles'
import SmallHeading from './SmallHeading'

const StyledSubscribe = styled.section`
  text-align: center;
  margin-top: ${desktopVW(200)};
  ${mobileBreakpoint} {
    margin-top: ${mobileVW(70)};
  }
`

const Subscription = styled.div``

const Field = styled.div`
  font-size: ${desktopVW(100)};
  margin: ${desktopVW(50)} 0 ${desktopVW(50)};
  ${mobileBreakpoint} {
    font-size: ${mobileVW(18)};
    margin: ${mobileVW(36)} 0 ${mobileVW(14)};
  }
`

const Input = styled.input`
  background: ${colors.black};
  border: none;
  text-align: center;
  color: ${colors.white};
  display: ${({ visible }) => (visible ? 'block' : 'none')}
  outline: 0;

  &:focus{
    outline: 0;
  }
  &::placeholder {
    color: ${colors.white};
  }
`

const Button = styled.button`
  display: inline-block;
  font-family: ${font.extended};
  font-size: ${mobileVW(10)};
  letter-spacing: ${letterSpacing(6)};
  text-transform: uppercase;
  border-bottom: 1px solid white;
  padding: ${mobileVW(12)} 0 ${mobileVW(14)};
  color: ${colors.white};

  ${desktopBreakpoint} {
    font-size: ${desktopVW(10)};
    padding: ${desktopVW(0)} ${desktopVW(0)} ${desktopVW(10)};
  }
`

const SuccessMessage = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const Subscribe = () => {
  const [email, setEmail] = useState('')
  const [emailValid, setEmailValid] = useState(false)

  const handleChangeEmail = e => {
    setEmail(e.target.value)
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setEmailValid(regex.test(e.target.value.toLowerCase()))
  }
  return (
    <StyledSubscribe>
      <SmallHeading>STAY UP TO DATE</SmallHeading>
      <MailchimpSubscribe
        url='https://askphill.us20.list-manage.com/subscribe/post?u=593bd87171369e52e1ce42eee&id=5f5e4a4932'
        render={({ subscribe, status }) => (
          <Subscription>
            <Field>
              {status !== 'success' ? (
                <Input
                  visible={status !== 'success'}
                  type='email'
                  placeholder='Your@mail.com'
                  onChange={handleChangeEmail}
                  value={email}
                />
              ) : (
                <SuccessMessage visible={status === 'success'}>
                  You rock
                </SuccessMessage>
              )}
            </Field>
            {status !== 'success' ? (
              <Button
                type='submit'
                onClick={e => {
                  e.preventDefault()
                  subscribe({ EMAIL: email })
                }}
                disabled={!emailValid}
              >
                SUBSCRIBE
              </Button>
            ) : null}
          </Subscription>
        )}
      />
    </StyledSubscribe>
  )
}

export default Subscribe
