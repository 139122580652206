import styled from 'styled-components'

import {
  mobileVW,
  desktopVW,
  font,
  colors,
  letterSpacing,
  desktopBreakpoint,
} from '../styles'

const SmallHeading = styled.h3`
  font-family: ${font.extended};
  font-size: ${mobileVW(10)};
  letter-spacing: ${letterSpacing(6)};
  text-transform: uppercase;
  color: ${colors.grey};
  font-weight: 800;

  ${desktopBreakpoint} {
    font-size: ${desktopVW(10)};
  }
`

export default SmallHeading
